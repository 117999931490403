import { graphql } from 'gatsby'
import React from 'react'

import SimpleHead from 'components/head/SimpleHead'
import pageData from 'data/pages/Contents/home.json'
import HomepageLayout from 'templates/homepage'

const IndexPage = ({ data: images, location }) => (
  <HomepageLayout images={images} location={location} pageData={pageData} />
)

export const query = graphql`
  query {
    previewDesktop: file(relativePath: { regex: "/^home\/en\/img\/header-desktop-preview\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 2224, placeholder: BLURRED)
      }
    }
    previewMobile: file(relativePath: { regex: "/^home\/en\/img\/header-mobile-preview\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 490, placeholder: BLURRED)
      }
    }
    swap: file(relativePath: { regex: "/^home\/en\/img\/image-swap\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 2160, placeholder: BLURRED)
      }
    }
    stake: file(relativePath: { regex: "/^home\/en\/img\/image-stake\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 2160, placeholder: BLURRED)
      }
    }
    tokens: file(relativePath: { regex: "/^home\/en\/img\/image-tokens\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 2160, placeholder: BLURRED)
      }
    }
    hardware: file(relativePath: { regex: "/^home\/en\/img\/image-hardware\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 2160, placeholder: BLURRED)
      }
    }
    hardwareLedger: file(relativePath: { regex: "/^home\/en\/img\/image-hardware-ledger\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 2160, placeholder: BLURRED)
      }
    }
  }
`

export default IndexPage

export const Head = () => {
  const { title, description, keywords } = pageData

  return <SimpleHead title={title} description={description} keywords={keywords} />
}
